<template>
    <div>
        <div class="top_div">
            <div class="search_div_father">
                <common-header title="预配舱单查询">
                    <div class="input_div_father" slot="content">
                        <span class="inputTitle span">提单号：</span>
                        <div class="input_div">
                            <el-popover
                                v-if="queryInfoList.length"
                                trigger="click"
                                placement="bottom-start"
                                popper-class="blno_history"
                                ref="popoverRef"
                            >
                                <div class="el-autocomplete-suggestion">
                                    <div class="el-scrollbar">
                                        <div
                                            class="el-autocomplete-suggestion__wrap el-scrollbar__wrap"
                                        >
                                            <ul
                                                class="el-scrollbar__view el-autocomplete-suggestion__list"
                                            >
                                                <li
                                                    v-for="item in queryInfoList"
                                                    :key="item.id"
                                                    style="
                                                        display: flex;
                                                        justify-content: space-between;
                                                    "
                                                    @click="
                                                        handleSelectKey(
                                                            item.value,
                                                        )
                                                    "
                                                >
                                                    <span>
                                                        {{ item.value }}
                                                    </span>
                                                    <i
                                                        class="el-icon-delete"
                                                        style="
                                                            line-height: 34px;
                                                            margin-left: 10px;
                                                        "
                                                        @click.stop="
                                                            handleDeleteKey(
                                                                item.id,
                                                            )
                                                        "
                                                    ></i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <el-input
                                    slot="reference"
                                    placeholder="请输入提单号"
                                   maxlength="30"
                                    v-model="searchBlno"
                                    clearable
                                    @input="checkInputBlno()"
                                    class="input-with-select-top"
                                    @keyup.enter.native="getBoxInfo()"
                                    style="width: 210px"
                                ></el-input>
                            </el-popover>
                            <el-input
                                v-else
                                slot="reference"
                                placeholder="请输入提单号"
                                v-model="searchBlno"
                                maxlength="30"
                                clearable
                                @input="checkInputBlno()"
                                class="input-with-select-top"
                                style="width: 210px"
                                @keyup.enter.native="getBoxInfo()"
                            ></el-input>
                        </div>
                        <!-- <el-button type="danger" @click="isShowBlnoMain=!isShowBlnoMain">青岛拼箱主提单号</el-button> -->
                        <span class="inputTitle span">青岛/天津拼箱主单号：</span>
                        <div class="input_div">
                            <el-input
                                placeholder="青岛/天津 请输入"
                                v-model="blnoMain"
                                clearable
                                @input="checkInputBlno()"
                                class="input-with-select-top"
                                @keyup.enter.native="getBoxInfo()"
                                style="width: 210px"
                            ></el-input>
                        </div>
                        
                        <div class="btn_div">
                            <el-button
                                type="primary"
                                class="refresh_btn"
                                @click="getDockInfo(searchBlno)"
                                :loading="totalLoading || buttonLoading"
                            >
                                查询
                            </el-button>
                            <el-button
                                class="refresh_btntwo"
                                :loading="totalLoading || buttonLoading"
                                @click="resetAll()"
                            >
                                重置
                            </el-button>
                        </div>
                    </div>
                    <!-- <div slot="footer" class="header_footer">
                        <img src="@/assets/icon/warning.svg" />
                        <div>
                            青岛拼箱查询需要输入主提单号，点击<span style="font-size:1000">本段文字</span>可展开收缩青岛主提单号
                        </div>
                    </div> -->
                </common-header>
            </div>
        </div>

        <div class="data_div">
       

            <div class="table_div">
                <div class="data_search_div" key="fwPreInfo">
                    <div class="data_search_left_div">
                        <h1 class="status">| 预配舱单</h1>
                    </div>
                    <div class="flex_div">
                        <column-set
                            :table-list="queryList3"
                            page-key="boxQuery_fwPreInfo"
                            :reset-data="{ NB: QUERYTABLE3.queryTable3 }"
                            save-mutation="table/queryTableList3"
                            reset-mutation="table/queryTableList3"
                        ></column-set>
                    </div>
                </div>
                <el-table
                    border
                    stripe
                    v-loading="loadingFwPreInfo"
                    max-height="190"
                    ref="multipleTable"
                    :key="tableKey3"
                    :cell-class-name="cellClass"
                    :data="dockInfo"
                    style="width: 100%"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        prop="num"
                        label="序号"
                        width="120"
                    ></el-table-column>

                    <template v-for="item in queryList3">
                        <el-table-column
                            v-if="item.show"
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :min-width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <template v-if="item.prop === 'ctnInfoArr'">
                                    <div v-for="ctn in scope.row.ctnInfoArr" :key="ctn.contaId">
                                        {{ `${ctn.contaId} / ${ctn.contaSizeType}` }}
                                    </div>
                                </template>
                                <template v-else>
                                    <span v-if="item.prop.includes('Time')">
                                        {{ formatTime(scope.row[item.prop]) }}
                                    </span>
                                    <span v-else-if="item.prop === 'portCode'">{{ `${scope.row.portCodeText}(${scope.row.portCode})` }}</span>
                                    <span v-else>
                                        {{ scope.row[item.prop] }}
                                    </span>
                                </template>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import ColumnSet from "@/components/common/ColumnSet";
import moment from "moment";

import {
    searchHistorySuggestion,
    deleteHistorySuggestion,
} from "@/api/boxQuery";
import {
    setRightForrmat,
} from "@/utils/validate";
import { get } from "sortablejs";
import { getManifestInfo } from "@/utils";
export default {
    name: "ManifestQuery",
    data() {
        return {
            loadingFwPreInfo: false, // 预配舱单信息加载
            searchBlno: "", //搜索框提单号
            blnoMain:"",//主提单号
            tableKey3: "fwPreInfoTable",
            dockInfo: [], //预配舱单信息
            buttonLoading: false,
            queryInfoList: [], // 查询提单号历史记录数据
            isShowBlnoMain: false, // 是否显示主提单号
        };
    },
    components: {
        CommonHeader,
        ColumnSet,
    },
    mounted() {
        this.Init();
    },
    watch: {
        isShowBlnoMain() {
            this.blnoMain = ''
        },
    },
    computed: {
        queryList3: {
            get() {
                return this.$store.state.table.queryList3;
            },
            set(val) {
                this.$store.commit("table/queryTableList3", val);
            },
        },
        totalLoading() {
            return (
                this.loadingFwPreInfo
            );
        },
    },
    methods: {
        Init() {
            // 获取搜索记录
            this.querySuggestions();
           
            // if (this.$store.state.ship.aloneDockInfo) {
            //     this.dockInfo = this.$store.state.ship.aloneDockInfo;
            // }
            if (
                this.$store.state.table.queryList3.length ===
                this.RESETTABLE.queryTable3.length
            ) {
                this.queryList3 = this.$store.state.table.queryList3;
            } else {
                this.queryList3 = this.QUERYTABLE3.queryTable3;
            }

        },

        // 处理提单号历史记录

        // 选择
        handleSelectKey(item) {
            // 赋值
            this.searchBlno = item;
            // 自动失去焦点
            this.$refs.popoverRef.doClose();
            // 查询
            this.getDockInfo(this.searchBlno);
        },

        // 查询历史记录
        querySuggestions() {
            searchHistorySuggestion({
                // code: this.$store.state.other.systemKey,
                code: "NB",
            }).then(({ data }) => {
                this.queryInfoList = data.data.map((item) => ({
                    value: item.blno,
                    id: item.id,
                }));
            });
        },

        // 删除
        handleDeleteKey(id) {
            // 为了用户体验
            // 前端先手动删除
            this.queryInfoList = this.queryInfoList.filter(
                (item) => item.id !== id,
            );
            // 后端再同步
            deleteHistorySuggestion(id);
        },

        resetFather(type) {
            this.reset();
            if (type !== "table") {
                this.searchBlno = "";
            }
            this.dockInfo = [];
            // this.$store.commit("ship/editAloneDockInfo", this.dockInfo);
        },
        

        //检测订阅跟踪输入的关键字格式
        checkInputBlno() {
            // 提单号
            if (this.searchBlno) {
                this.searchBlno = setRightForrmat(this.searchBlno);
            }
            // 主提单号
            if(this.blnoMain){
                this.blnoMain = setRightForrmat(this.blnoMain);
            }
        },
        

        // 查询预配舱单信息
        getDockInfo(blno) {
            if (!blno) {
                this.$message.warning("提单号不得为空");
                return;
            }
            this.buttonLoading = true;
            this.dockInfo = [];
            getManifestInfo(blno, this.blnoMain).then((data) => {
                this.dockData(data)
            }).finally(() => {
                this.buttonLoading = false;
            });
        },

       

        // 预配单(只有一条)
        dockData(data) {
            this.dockInfo = [];
            const { signsTypeText, listStatText, saveTime } = data;
            var tempDock = {
                ...data,
                num: this.dockInfo.length + 1,
                listStatText: `${signsTypeText}(${listStatText})`,
                saveTime: saveTime&&saveTime !== '无' ? moment(saveTime).format('YYYY-MM-DD HH:mm:ss') : "-",
            };
            tempDock && this.dockInfo.push(tempDock);
            // this.$store.commit("ship/editAloneDockInfo", this.dockInfo);
   
        },
        // 提单号，集装箱号重置(所有)
        resetAll() {
            this.searchBlno = "";
            this.resetFather("table");
        },
      

        // 时间处理切割
        formatTime(time) {
            const result = time && time !== "-" ? time.slice(5, time.length - 3) : "-";
            return result;
        },

        getBoxInfo() {
            this.getDockInfo(this.searchBlno);
        },

       
        
        //设置单元格样式
        cellClass({ row, column, rowIndex, columnIndex }) {
            if (column.property) {
                //若有查验底色,若码放显示不能放行，取消放行等信息，若临近截关期或截港期，底色显示粉色
                if (
                    (column.property === "boxCusmov" && row.boxCusmov) ||
                    (column.property === "boxPassFlag" &&
                        row.boxPassFlag &&
                        row.boxPassFlag != "Y") ||
                    (column.property === "portCloseTime" &&
                        row.portCloseTime &&
                        this.judgeTimeclose(
                            row.portCloseTime,
                            this.$store.state.ship.portCloseTime,
                        )) ||
                    (column.property === "ctnApplyEndTime" &&
                        row.ctnApplyEndTime &&
                        this.judgeTimeclose(
                            row.ctnApplyEndTime,
                            this.$store.state.ship.ctnApplyEndTime,
                        ))
                ) {
                    return "pink_cell";
                }
            }
        },
        //判断时间是否小于设置的时间
        judgeTimeclose(time, markHour) {
            var date = new Date();
            var enddate = new Date(time);
            if (enddate > date) {
                return (enddate - date) / 1000 / 3600 < markHour;
            }
            return false;
        },
    },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- 处理element-ui加socped修改样式不生效bug,单独拎出来一个style -->
<style>
.blno_history {
    padding: 0 !important;
}

.el-autocomplete-suggestion {
    margin: 0;
}
</style>
<style lang="stylus" scoped>
.el-autocomplete-suggestion__wrap{
  width:300px;
  .el-autocomplete-suggestion__list{
    li {
      padding: 0 15px;
    }
    span {
      flex-grow:1;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
/deep/ .pink_cell {
    background-color: #FF98A9 !important;
}

.radio_width {
    width: 100%;

    /deep/ .el-radio__input {
        line-height: 36px;
    }
}

.search_input_div {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background: #fff;
    align-items: baseline;

    // margin: auto;
    .el-input {
        width: 15rem;
    }

    /deep/ .el-input-group__append,
    .el-input-group__prepend {
        border: none;
    }
}

.refresh_btn {
    margin: 0 1rem;
    cursor: pointer;
    border-radius: 6px;
    margin-left: 30px;
    // background: #fff;
    // height: 4rem;
}

.refresh_btntwo {
    margin: 0 1rem;
    cursor: pointer;
    border-radius: 6px;
    // background: #fff;
    // height: 4rem;
}

.p_green {
    color: var(--GREEN-1E9855);
}

.p_red {
    color: var(--RED-C11C20);
}

.el-select {
    width: 12rem;
}

.status {
    width: 12rem;
    margin-left:1rem;
    color:#b88230;
}

.span-status {
    font-family: 'Lucida Calligraphy', cursive, serif, sans-serif;
    font-size: 18px;
    font-weight: bold;
    width: 15rem;
    text-align: center;
    position: relative;
    background-color: rgba(125, 220, 255, 0.2);
    // top: 25%;
}

p {
    text-align: center;
    margin: 0;
}

.txt_time {}

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.input_div_father {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin: auto;
    width: 70%;
    justify-content: center;
}

.input_div {
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    border: none;
    background: #fff;
    justify-content: center;
    align-items: center;
}


.small_title {
    line-height: 1.5;
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    user-select: none;
}

.search_sailing {
    margin-top: 50px;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.el-select .el-input {
    width: 13rem;
}

.query-input_center:before,
.query-input_right:before {
    content: '';
    height: 2rem;
    margin-top: 12px;
    border-right: 1px solid #ccc;
}

.query-input_btn {
    cursor: pointer;
    width: 11rem;
    height: 4rem;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border-radius: 0 4px 4px 0;
    background: #ff9807;
    border: none;
}

.input-with-select-top {
    background-color: #fff;
    border-radius: 4px;
}

.input-with-select-sailing {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.data_div {
    min-height: 60vh;
    margin: 1rem;
    // margin-left: 6rem;
    // margin-right: 6rem;
    background: #fff;
    padding: 1rem 2rem;
    // border: 1px solid var(--RED-79031D);
    // border-radius: 4px;
}

.table_div {
    min-height: 60rem;
}

.passIcon {
    width: 2rem;
    height: 2rem;
    background-size: 100% 100%;
}

.data_search_div {
    // margin: 0.5rem 0;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // background: #fff;
    .data_search_left_div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    /deep/ .el-input-group {
        width: 25rem;
        // margin-left: 1rem;
    }

    /deep/ .el-input__inner {
        // border: 1px solid #DCDFE6;
        border: none;
    }

    /deep/ .el-input--suffix {
        padding: 0;
    }
}

/deep/ .el-table--border th.el-table__cell {
    color: #000 !important;
    text-align: center;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
    font-weight: 700;
}

/deep/ .el-table .cell {
    font-weight: 700;
}

/deep/ .el-table .el-table__cell {
    padding: 2px 0;
    text-align: center;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #f1f3f6;
}

.el-tree {
    padding: 0 2rem 0 0;
}

.tree_btn_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.flex_div {
    display: flex;
    flex-direction: row;
}

.download_btn_div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .download_btn {
        margin-left: 1rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
}

.btn_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagination_div {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.table_control_div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.detail {
    cursor: pointer;
    color: rgb(85, 114, 253);
    user-select: none;
}

.add_remark_p {
    cursor: pointer;
    color: rgb(85, 114, 253);
    user-select: none;
}

.line {
    width: 2px;
    height: 80%;
    background: var(--GRAY-dcdcdc);
}

.delete {
    cursor: pointer;
    color: rgb(213, 13, 0);
    user-select: none;
}

.top_div {
    background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}

.img_back {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.btn {
    background-color: unset;
    border-radius: 4px;
    border: none;
    padding: 12px 12px;

    span {
        font-family: Microsoft YaHei UI;
        color: #fff;
        font-size: 15px;
    }
}

.span {
    font-family: Microsoft YaHei UI;
    color: #fff;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_footer{
    display: flex;
    align-items: center;
    padding-top:10px;
    height: 20px;
    font-size:16px;
    color:#ED9B50;
    cursor: pointer;
    user-select: none;
    img {
        margin-right: 4px;
        width: 14px;
        height: 14px;
    }
}

.spantwo {
    font-family: Microsoft YaHei UI;
    color: #000;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(64, 158, 255);
}

.inputTitle{
    font-weight:bold;
}
</style>
